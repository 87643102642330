/* eslint-disable prettier/prettier */
// eslint-disable-next-line no-unused-vars
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';

export interface ConfirmationDialogRawProps {
  handleClose: () => void;
}

function TermsAndConditions(props: ConfirmationDialogRawProps) {
  const { handleClose } = props;

  return (
    <div>
      <DialogContentText>
        1. Acceptance of Terms By submitting your ideas to Stanbic Innovation
        Portal, you agree to abide by these terms and conditions. If you do not
        agree with any of these terms, please do not submit your ideas. 
        <br/>
        <br/>
        2.Eligibility To submit ideas. Employees of Stanbic Bank
        and their immediate family members are not eligible to participate.
        <br/>
        <br/>
        3. Idea Submission Guidelines All ideas must be original work of the
        submitter and not violate the intellectual property rights of any third
        party. Submissions must not contain offensive, defamatory, or
        inappropriate content as determined by Stanbic Bank. 
        <br/>
        <br/>
        4.Intellectual Property Rights By submitting an idea, you grant
        Stanbic Bank a non-exclusive, royalty-free, perpetual,
        and worldwide license to use, reproduce, modify, adapt, publish,
        translate, create derivative works from, distribute, perform, and
        display the content (in whole or part) and/or to incorporate it in other
        works in any form, media, or technology now known or later developed. 
        <br/>
        <br/>
        5.Confidentiality Ideas submitted to the portal are considered
        non-confidential and non-proprietary. Stanbic Bank is not
        under any obligation of confidentiality, express or implied, with
        respect to the submitted ideas. 
        <br/>
        <br/>
        6. Selection and Implementation Stanbic Bank does not guarantee that any submitted ideas
        will be implemented. The selection of ideas for further development or
        implementation will be at the sole discretion of [Company/Organization
        Name]. 
        <br/>
        <br/>
        7. No Compensation Participants will not receive any compensation
        for submitting ideas or for any subsequent use of their ideas by
        Stanbic Bank, unless otherwise agreed in writing. 
        <br/>
        <br/>
        8. Indemnification You agree to indemnify and hold harmless
        Stanbic Bank and its affiliates, officers, agents,
        employees, and partners from any claim or demand made by any third party
        due to or arising out of your ideas, your use of the portal, your
        violation of these terms, or your violation of any rights of another. 
        <br/>
        <br/>
        9.Changes to Terms Stanbic Bank reserves the right to
        modify these terms at any time. We recommend participants review the
        terms periodically for changes. 10. Governing Law These terms shall be
        governed by and construed in accordance with the laws of [Jurisdiction],
        without giving effect to any principles of conflicts of law.
      </DialogContentText>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </div>
  );
}
export default TermsAndConditions;
